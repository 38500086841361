import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppOption } from 'utils/types/app.types';
import { RootState } from '../../app/store';
import { CalculationState, CalculationType } from './calculation.types';

const initialState: CalculationState = {
  type: 'calculation',
  city: '',
  country: null,
  countryString: '',
  date: [],
  nights: '',
  adults: 2,
  babies: [],
  name: '',
  phone: '',
  policyIsChecked: false,
  ui: {
    calculationIsOpen: false,
    cityIsOpen: false,
    countryIsOpen: false,
    dateIsOpen: false,
    nightsIsOpen: false,
    guestsIsOpen: false,
  },
};

export const calculationSlice = createSlice({
  name: 'calculation',
  initialState,
  reducers: {
    setCalculationCity: (state, action: PayloadAction<string>) => {
      state.city = action.payload;
    },
    setCalculationCountry: (
      state,
      action: PayloadAction<{ value: string; name: string }>
    ) => {
      state.country = action.payload;
    },
    setCalculationDate: (state, { payload }: PayloadAction<number[]>) => {
      state.date = payload;
    },
    setCalculationNights: (state, action: PayloadAction<string>) => {
      state.nights = action.payload;
    },
    changeCalculationAdults: (state, { payload }: PayloadAction<number>) => {
      state.adults = payload;
    },
    changeCalculationBabies: (state, { payload }: PayloadAction<number>) => {
      if (payload > state.babies.length) {
        state.babies = [
          ...state.babies,
          ...Array(payload - state.babies.length).fill(0),
        ];
      } else if (payload < state.babies.length) {
        state.babies = state.babies.filter((_, idx) => idx < payload);
      }
    },
    setCalculationBaby: (
      state,
      { payload }: PayloadAction<{ idx: number; age: number }>
    ) => {
      state.babies = [
        ...state.babies.slice(0, payload.idx),
        payload.age,
        ...state.babies.slice(payload.idx + 1),
      ];
    },
    setCalculationName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setCalculationPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    toggleCalculationPolicy: (state) => {
      state.policyIsChecked = !state.policyIsChecked;
    },

    clearCalculation: (state) => {
      state.city = '';
      state.country = null;
      state.date = [];
      state.nights = '';
      state.adults = 2;
      state.babies = [];
      state.name = '';
      state.phone = '';
      state.policyIsChecked = false;
    },

    openCalculationCity: (state) => {
      state.ui.cityIsOpen = true;
    },
    closeCalculationCity: (state) => {
      state.ui.cityIsOpen = false;
    },
    openCalculationCountry: (state) => {
      state.ui.countryIsOpen = true;
    },
    closeCalculationCountry: (state) => {
      state.ui.countryIsOpen = false;
    },
    openCalculationDate: (state) => {
      state.ui.dateIsOpen = true;
    },
    closeCalculationDate: (state) => {
      state.ui.dateIsOpen = false;
    },
    openCalculationNights: (state) => {
      state.ui.nightsIsOpen = true;
    },
    closeCalculationNights: (state) => {
      state.ui.nightsIsOpen = false;
    },
    openCalculationGuests: (state) => {
      state.ui.guestsIsOpen = true;
    },
    closeCalculationGuests: (state) => {
      state.ui.guestsIsOpen = false;
    },

    openCalculation: (
      state,
      {
        payload,
      }: PayloadAction<{
        type: CalculationType;
        hotel?: string;
        country?: string;
        nights?: string;
      }>
    ) => {
      state.type = payload.type;
      state.countryString = payload.country;
      state.hotel = payload.hotel;
      state.nights = payload.nights ?? '';
      state.ui.calculationIsOpen = true;
    },
    closeCalculation: (state) => {
      state.ui.calculationIsOpen = false;
    },
  },
});

export const {
  setCalculationCity,
  setCalculationCountry,
  setCalculationDate,
  setCalculationNights,
  changeCalculationAdults,
  changeCalculationBabies,
  setCalculationBaby,
  setCalculationName,
  setCalculationPhone,
  toggleCalculationPolicy,

  clearCalculation,

  openCalculationCity,
  closeCalculationCity,
  openCalculationCountry,
  closeCalculationCountry,
  openCalculationDate,
  closeCalculationDate,
  openCalculationNights,
  closeCalculationNights,
  openCalculationGuests,
  closeCalculationGuests,
  openCalculation,
  closeCalculation,
} = calculationSlice.actions;

export const selectCalculation = (state: RootState) => state.calculation;

export const calculationReducer = calculationSlice.reducer;
