import { useRef } from 'react';
import cn from 'classnames';
import styles from './guests.module.scss';
import { GuestsProps } from './guests.props';

import { Portal } from 'components/other/portal/Portal';
import { Dropdown } from 'components/layout/dropdown/Dropdown';
import { Select } from 'components/forms/select/Select';
import { ageOptions } from 'utils/constants/ageOptions.constants';

export const Guests = ({
  adults,
  babies,
  isOpen,
  onClick,
  onOutsideClick,
  onChangeAdults,
  onChangeBabies,
  onSetBaby,
  className,
}: GuestsProps): JSX.Element => {
  const faceRef = useRef<HTMLDivElement>(null);

  const moduleStyles = cn(styles.module, className, {
    [styles.filled]: adults,
  });

  return (
    <>
      <div className={moduleStyles} ref={faceRef} onClick={onClick}>
        <span>Туристы</span>
        <strong>
          {adults && `${adults} взр.`}
          {babies.length > 0 && ` ${babies.length} реб.`}
        </strong>
      </div>
      {isOpen && (
        <Portal>
          <Dropdown
            faceRef={faceRef}
            onOutsideClick={onOutsideClick}
            position="bottomLeft"
          >
            <div className={styles.dropdown}>
              <div className={styles.adults}>
                <span className={styles.title}>Взрослых</span>
                <button
                  className={styles.button}
                  onClick={() => adults > 1 && onChangeAdults(adults - 1)}
                >
                  -
                </button>
                <span className={styles.value}>{adults}</span>
                <button
                  className={styles.button}
                  onClick={() => adults < 5 && onChangeAdults(adults + 1)}
                >
                  +
                </button>
              </div>

              <div className={styles.babies}>
                <span className={styles.title}>Детей</span>
                <button
                  className={styles.button}
                  onClick={() =>
                    babies.length > 0 && onChangeBabies(babies.length - 1)
                  }
                >
                  -
                </button>
                <span className={styles.value}>{babies.length}</span>
                <button
                  className={styles.button}
                  onClick={() =>
                    babies.length < 5 && onChangeBabies(babies.length + 1)
                  }
                >
                  +
                </button>
              </div>

              <div className={styles.list}>
                {babies.map((item, idx) => (
                  <Select
                    className={styles.select}
                    options={ageOptions}
                    paddingLeft={0}
                    value={item}
                    onChange={(event) => onSetBaby(idx, +event.target.value)}
                  />
                ))}
              </div>
              {/* <button
                className={styles.confirm}
                onClick={onOutsideClick}
              >
                <span>Готово</span>
              </button> */}
            </div>
          </Dropdown>
        </Portal>
      )}
    </>
  );
};
