import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Guests } from 'components/elements/guests/Guests';
import { Nights } from 'components/elements/nights/Nights';
import { batch } from 'react-redux';

import {
  changeQuizAdults,
  changeQuizBabies,
  closeQuizGuests,
  closeQuizNights,
  openQuizGuests,
  openQuizNights,
  selectQuiz,
  setQuizBaby,
  setQuizNights,
} from '../quiz.slice';
import styles from './quizTwo.module.scss';
import { QuizTwoProps } from './quizTwo.props';

export const QuizTwo = ({ nightsOptions }: QuizTwoProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const quiz = useAppSelector(selectQuiz);

  return (
    <div className={styles.module}>
      <Nights
        className={styles.nights}
        nights={quiz.nights}
        isOpen={quiz.ui.nightsIsOpen}
        onClick={() =>
          dispatch(quiz.ui.nightsIsOpen ? closeQuizNights() : openQuizNights())
        }
        onOutsideClick={() => dispatch(closeQuizNights())}
        onSetNights={(nights) =>
          batch(() => {
            dispatch(setQuizNights(nights));
            dispatch(closeQuizNights());
          })
        }
        options={nightsOptions}
      />

      <Guests
        className={styles.guests}
        isOpen={quiz.ui.guestsIsOpen}
        onClick={() =>
          dispatch(quiz.ui.guestsIsOpen ? closeQuizGuests() : openQuizGuests())
        }
        onOutsideClick={() => dispatch(closeQuizGuests())}
        onChangeAdults={(amount) => dispatch(changeQuizAdults(amount))}
        onChangeBabies={(amount) => dispatch(changeQuizBabies(amount))}
        onSetBaby={(idx, age) => dispatch(setQuizBaby({ idx, age }))}
        adults={quiz.adults}
        babies={quiz.babies}
      />
    </div>
  );
};
