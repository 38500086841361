import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  new Swiper('.hot__slider .swiper', {
    loop: true,
    spaceBetween: 20,
    loopedSlides: 10,

    navigation: {
      nextEl: '.hot__next',
      prevEl: '.hot__prev',
    },

    // pagination: {
    //   el: '.gallery__pagination',
    //   clickable: true,
    // },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  });
};

const initInnerPagination = () => {
  const items = document.querySelectorAll<HTMLDivElement>('.hot__wrapper');

  items.forEach((item) => {
    const imageWrapper = item.querySelector<HTMLDivElement>('.hot__images');
    const prev = item.querySelector<HTMLButtonElement>('.hot__inner-prev');
    const next = item.querySelector<HTMLButtonElement>('.hot__inner-next');
    const paginationSpans = item.querySelectorAll<HTMLSpanElement>(
      '.hot__inner-pagination-item'
    );

    let counter = 0;
    const quantity = imageWrapper?.children.length || 0;

    prev?.addEventListener('click', () => {
      if (counter > 0) {
        imageWrapper!.style.transform = `translate3d(${
          --counter * -100
        }%, 0, 0)`;
        paginationSpans.forEach((span) =>
          span.classList.remove('hot__inner-pagination-item--active')
        );
        paginationSpans[counter].classList.add(
          'hot__inner-pagination-item--active'
        );
      }
    });

    next?.addEventListener('click', () => {
      if (counter < quantity - 1) {
        imageWrapper!.style.transform = `translate3d(${
          ++counter * -100
        }%, 0, 0)`;
      }
      paginationSpans.forEach((span) =>
        span.classList.remove('hot__inner-pagination-item--active')
      );
      paginationSpans[counter].classList.add(
        'hot__inner-pagination-item--active'
      );
    });
  });
};

const initZoom = () => {
  const imageContainers =
    document.querySelectorAll<HTMLDivElement>('.hot__images');
  imageContainers.forEach((container) => {
    const images = container.querySelectorAll<HTMLDivElement>('.hot__image');

    const items = Array.from(images).map((item) => ({
      href: item.getAttribute('href'),
    }));

    const glightbox = GLightbox({
      elements: items,
      loop: true,
    });

    images.forEach((image) => {
      image?.addEventListener('click', (event) => {
        event.preventDefault();
        glightbox.openAt(image.dataset.index);
      });
    });
  });
};

const hot = {
  init: () => {
    initSlider();
    initInnerPagination();
    initZoom();
  },
};

export default hot;
