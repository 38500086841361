import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  new Swiper('.team__slider .swiper', {
    loop: true,
    spaceBetween: 20,
    loopedSlides: 10,

    navigation: {
      nextEl: '.team__next',
      prevEl: '.team__prev',
    },

    // pagination: {
    //   el: '.gallery__pagination',
    //   clickable: true,
    // },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  });
};

const team = {
  init: () => {
    initSlider();
  },
};

export default team;
