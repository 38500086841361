import { useAppDispatch } from 'app/hooks';
import { CloseButton } from 'components/elements/closeButton/CloseButton';
import { Policy } from 'components/elements/policy/Policy';
import { Input } from 'components/forms/input/Input';
import { openTextModal, openThanks } from 'features/common/common.slice';
import {
  clearForm,
  closeFormModal,
  selectForm,
  setFormName,
  setFormPhone,
  toggleFormPolicy,
} from 'features/form/form.slice';
import { FormEventHandler } from 'react';
import { useSelector } from 'react-redux';
import styles from './formModalBody.module.scss';
// import {
//   clearForm,
//   closeFormModal,
//   openFormArrival,
//   openFormDeparture,
//   selectForm,
//   setFormName,
//   setFormPhone,
//   toggleFormPolicy,
// } from 'store/form/form.slice';

declare const ym: any;
// declare const fbq: any;

export const FormModalBody = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const form = useSelector(selectForm);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    ym(88411607, 'reachGoal', 'kick');
    // fbq('track', 'Lead');

    dispatch(closeFormModal());
    setTimeout(() => dispatch(openThanks('request')), 300);

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'Получить подборку',
        city: form.city,
        country: form.country?.name,
        date: form.date
          .map((item) => new Date(item).toLocaleDateString().slice(0, -5))
          .join(' - '),
        nights: form.nights,
        adults: form.adults,
        babies: form.babies,
        name: form.name,
        phone: form.phone,
      }),
    });

    dispatch(clearForm());
  };

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeFormModal())}
      />
      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>Получить подборку</h2>
        <Input
          className={styles.name}
          placeholder="Имя"
          value={form.name}
          onChange={(event) => dispatch(setFormName(event.target.value))}
        />
        <Input
          className={styles.phone}
          placeholder="Телефон"
          type="tel"
          pattern="^\+?[0-9() ]+"
          required
          value={form.phone}
          onChange={(event) => dispatch(setFormPhone(event.target.value))}
        />
        <Policy
          className={styles.policy}
          checked={form.policyIsChecked}
          onChange={() => dispatch(toggleFormPolicy())}
          onClick={() => dispatch(openTextModal('policy'))}
        />
        <button className={styles.button}>Отправить</button>
      </form>
    </div>
  );
};
