import { useAppDispatch } from 'app/hooks';
import { Cookie } from 'components/elements/cookie/Cookie';
import { AddReview } from 'features/addReview/AddReview';
import { openAddReview } from 'features/addReview/addReview.slice';
import { Calculation } from 'features/calculation/Calculation';
import { openCalculation } from 'features/calculation/calculation.slice';
import { Callback } from 'features/callback/Callback';
import { openCallback } from 'features/callback/callback.slice';
import { openTextModal } from 'features/common/common.slice';
import { TextModal } from 'features/common/textModal/TextModal';
import { Thanks } from 'features/common/thanks/Thanks';
import { Review } from 'features/review/Review';
import { openReview } from 'features/review/review.slice';
import { openFromReact } from 'utils/helpers/browser.helpers';

const form = document.getElementById('form');

function App() {
  const dispatch = useAppDispatch();

  openFromReact('.directions__button', ({ currentTarget }) => {
    if (currentTarget instanceof HTMLElement) {
      dispatch(
        openCalculation({
          type: 'direction',
          country: currentTarget.dataset.country,
        })
      );
    }
  });

  openFromReact('.hot__button', ({ currentTarget }) => {
    if (currentTarget instanceof HTMLElement) {
      dispatch(
        openCalculation({
          type: 'calculation',
          hotel: currentTarget.dataset.hotel,
          country: currentTarget.dataset.country,
          nights: currentTarget.dataset.nights,
        })
      );
    }
  });

  openFromReact('.reviews__button', ({ currentTarget }) => {
    if (currentTarget instanceof HTMLElement) {
      dispatch(
        openReview({
          name: currentTarget.dataset.name ?? '',
          grade: currentTarget.dataset.grade ?? '',
          date: currentTarget.dataset.date ?? '',
          text: currentTarget.dataset.text ?? '',
        })
      );
    }
  });

  openFromReact('.reviews__add', () => dispatch(openAddReview()));
  openFromReact('.callback__button', () => dispatch(openCallback()));

  openFromReact('#footer-policy', () => dispatch(openTextModal('policy')));
  openFromReact('#footer-rules', () => dispatch(openTextModal('rules')));
  openFromReact('#footer-cookie', () => dispatch(openTextModal('cookie')));

  return (
    <>
      <Calculation
        cityOptions={JSON.parse(form?.dataset.cities ?? '[]')}
        countryOptions={JSON.parse(form?.dataset.countries ?? '[]')}
        nightsOptions={JSON.parse(form?.dataset.nights ?? '[]')}
      />
      <Review />
      <Callback />
      <AddReview />

      <TextModal />
      <Thanks />
      <Cookie />
    </>
  );
}

export default App;
