import { useAppDispatch, useAppSelector } from 'app/hooks';
import { openThanks } from 'features/common/common.slice';
import { FormEventHandler } from 'react';
import { clearQuiz, selectQuiz } from './quiz.slice';
import styles from './quiz.module.scss';
import { Slider } from 'components/layout/slider/Slider';
import { QuizControl } from './quizControl/QuizControl';
import { QuizOne } from './quizOne/QuizOne';
import { QuizTwo } from './quizTwo/QuizTwo';
import { QuizThree } from './quizThree/QuizThree';
import { QuizProps } from './quiz.props';

declare const ym: any;
// declare const gtag: any;

export const Quiz = ({
  regionOptions,
  nightsOptions,
}: QuizProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const quiz = useAppSelector(selectQuiz);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    // if (!quiz.arrival || !quiz.departure) {
    //   return;
    // }

    ym(88411607, 'reachGoal', 'kick');
    // gtag('event', 'submit', { event_category: 'form' });

    setTimeout(() => dispatch(openThanks('request')), 300);

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'Квиз',
        region: quiz.region,
        // country: quiz.country,
        date: quiz.date
          .map((item) => new Date(item).toLocaleDateString().slice(0, -5))
          .join(' - '),
        nights: quiz.nights,
        adults: quiz.adults,
        babies: quiz.babies,
        name: quiz.name,
        phone: quiz.phone,
      }),
    });

    dispatch(clearQuiz());
  };

  return (
    <div className={styles.module}>
      {/* <p className={styles.steps}>Шаг {quiz.step} из 3</p> */}
      <form className={styles.form} onSubmit={handleSubmit}>
        <Slider className={styles.slider} offsetBy={quiz.step - 1}>
          <QuizOne regionOptions={regionOptions} />
          <QuizTwo nightsOptions={nightsOptions} />
          <QuizThree />
        </Slider>
        <QuizControl />
      </form>
    </div>
  );
};
