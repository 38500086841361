import { useRef } from 'react';
import cn from 'classnames';
import { DatePickerProps } from './datePicker.props';
import styles from './datePicker.module.scss';
import { Portal } from 'components/other/portal/Portal';
import { Dropdown } from 'components/layout/dropdown/Dropdown';
import { Calendar } from './calendar/Calendar';

export const DatePicker = ({
  name,
  isOpen,
  onClick,
  onOutsideClick,
  onDayClick,
  value,
  className,
}: DatePickerProps): JSX.Element => {
  const faceRef = useRef<HTMLDivElement>(null);

  const moduleStyles = cn(styles.module, className, {
    [styles.filled]: value.length,
  });

  let start, end;

  if (value[0]) {
    start = new Date(value[0]).toLocaleDateString().slice(0, -5);
  }

  if (value[1]) {
    end = new Date(value[1]).toLocaleDateString().slice(0, -5);
  }

  return (
    <>
      <div className={moduleStyles} ref={faceRef} onClick={onClick}>
        <span>{name}</span>
        <strong>
          {start && `${start}`}
          {end && ` - ${end}`}
        </strong>
      </div>
      {isOpen && (
        <Portal>
          <Dropdown
            faceRef={faceRef}
            onOutsideClick={onOutsideClick}
            position="bottomRight"
          >
            <Calendar onDayClick={onDayClick} value={value} />
          </Dropdown>
        </Portal>
      )}
    </>
  );
};
