import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  new Swiper('.awards__slider .swiper', {
    loop: true,
    spaceBetween: 20,
    loopedSlides: 10,

    navigation: {
      nextEl: '.awards__next',
      prevEl: '.awards__prev',
    },

    // pagination: {
    //   el: '.gallery__pagination',
    //   clickable: true,
    // },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  });
};

const initZoom = () => {
  GLightbox({
    selector: '.awards__slide',
    loop: true,
  });
};

const cafe = {
  init: () => {
    initSlider();
    initZoom();
  },
};

export default cafe;
