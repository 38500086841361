import { useRef } from 'react';
import cn from 'classnames';
import styles from './country.module.scss';
import { CountryProps } from './country.props';

import { Portal } from 'components/other/portal/Portal';
import { Dropdown } from 'components/layout/dropdown/Dropdown';

export const Country = ({
  country,
  options,
  isOpen,
  onClick,
  onOutsideClick,
  onSetCountry,
  className,
}: CountryProps): JSX.Element => {
  const faceRef = useRef<HTMLDivElement>(null);

  const moduleStyles = cn(styles.module, className, {
    [styles.filled]: country,
  });

  return (
    <>
      <div className={moduleStyles} ref={faceRef} onClick={onClick}>
        <span>Страна</span>
        {country && <strong>{country.name}</strong>}
      </div>
      {isOpen && (
        <Portal>
          <Dropdown
            faceRef={faceRef}
            onOutsideClick={onOutsideClick}
            position="bottomLeft"
          >
            <div className={styles.wrapper}>
              <div className={styles.list}>
                {options.map((item, idx) => (
                  <div
                    className={styles.item}
                    key={idx}
                    onClick={() => onSetCountry(item)}
                  >
                    <img src={`/countries/${item.value}.png`} alt={item.name} />
                    <span>{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </Dropdown>
        </Portal>
      )}
    </>
  );
};
