import { useAppDispatch, useAppSelector } from 'app/hooks';
import { City } from 'components/elements/city/City';
import {
  changeFormAdults,
  changeFormBabies,
  closeFormCity,
  closeFormCountry,
  closeFormDate,
  closeFormGuests,
  closeFormNights,
  openFormCity,
  openFormCountry,
  openFormDate,
  openFormGuests,
  openFormModal,
  openFormNights,
  selectForm,
  setFormBaby,
  setFormCity,
  setFormCountry,
  setFormDate,
  setFormNights,
} from './form.slice';
import styles from './form.module.scss';
import { batch } from 'react-redux';
import { Country } from 'components/elements/country/Country';
import { FormProps } from './form.props';
import { Nights } from 'components/elements/nights/Nights';
import { DatePicker } from 'components/forms/DatePicker/DatePicker';
import { Guests } from 'components/elements/guests/Guests';
import { FormModal } from './formModal/FormModal';

export const Form = ({
  cityOptions,
  countryOptions,
  nightsOptions,
}: FormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const form = useAppSelector(selectForm);

  const handleClick = () => {
    if (!form.city) {
      dispatch(openFormCity());
      return;
    }
    if (!form.country) {
      dispatch(openFormCountry());
      return;
    }
    if (form.date.length === 0) {
      dispatch(openFormDate());
      return;
    }
    if (!form.nights) {
      dispatch(openFormNights());
      return;
    }

    dispatch(openFormModal());
  };

  return (
    <>
      <div className={styles.module}>
        <City
          className={styles.city}
          city={form.city}
          isOpen={form.ui.cityIsOpen}
          onClick={() =>
            dispatch(form.ui.cityIsOpen ? closeFormCity() : openFormCity())
          }
          onOutsideClick={() => dispatch(closeFormCity())}
          onSetCity={(city) =>
            batch(() => {
              dispatch(setFormCity(city));
              dispatch(closeFormCity());
            })
          }
          options={cityOptions}
        />

        <Country
          className={styles.country}
          country={form.country}
          isOpen={form.ui.countryIsOpen}
          onClick={() =>
            dispatch(
              form.ui.countryIsOpen ? closeFormCountry() : openFormCountry()
            )
          }
          onOutsideClick={() => dispatch(closeFormCountry())}
          onSetCountry={(country) =>
            batch(() => {
              dispatch(setFormCountry(country));
              dispatch(closeFormCountry());
            })
          }
          options={countryOptions}
        />

        <DatePicker
          className={styles.date}
          name="Дата вылета"
          isOpen={form.ui.dateIsOpen}
          onClick={() =>
            dispatch(form.ui.dateIsOpen ? closeFormDate() : openFormDate())
          }
          onOutsideClick={() => dispatch(closeFormDate())}
          onDayClick={(range) => {
            const value = [];

            if (range?.from) {
              value[0] = range.from.valueOf();
            }
            if (range?.to) {
              value[1] = range.to.valueOf();
            }

            dispatch(setFormDate(value));

            if (value[1]) {
              dispatch(closeFormDate());
            }
          }}
          value={form.date}
        />

        <Nights
          className={styles.nights}
          nights={form.nights}
          isOpen={form.ui.nightsIsOpen}
          onClick={() =>
            dispatch(
              form.ui.nightsIsOpen ? closeFormNights() : openFormNights()
            )
          }
          onOutsideClick={() => dispatch(closeFormNights())}
          onSetNights={(nights) =>
            batch(() => {
              dispatch(setFormNights(nights));
              dispatch(closeFormNights());
            })
          }
          options={nightsOptions}
        />

        <Guests
          className={styles.guests}
          isOpen={form.ui.guestsIsOpen}
          onClick={() =>
            dispatch(
              form.ui.guestsIsOpen ? closeFormGuests() : openFormGuests()
            )
          }
          onOutsideClick={() => dispatch(closeFormGuests())}
          onChangeAdults={(amount) => dispatch(changeFormAdults(amount))}
          onChangeBabies={(amount) => dispatch(changeFormBabies(amount))}
          onSetBaby={(idx, age) => dispatch(setFormBaby({ idx, age }))}
          adults={form.adults}
          babies={form.babies}
        />

        <button className={styles.button} onClick={handleClick}>
          Получить подборку
        </button>
      </div>

      <FormModal />
    </>
  );
};
