import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { addReviewReducer } from 'features/addReview/addReview.slice';
import { calculationReducer } from 'features/calculation/calculation.slice';
import { callbackReducer } from 'features/callback/callback.slice';
import { commonReducer } from 'features/common/common.slice';
import { quizReducer } from 'features/quiz/quiz.slice';
import { reviewReducer } from 'features/review/review.slice';
import { formReducer } from '../features/form/form.slice';

export const store = configureStore({
  reducer: {
    form: formReducer,
    calculation: calculationReducer,
    common: commonReducer,
    review: reviewReducer,
    addReview: addReviewReducer,
    callback: callbackReducer,
    quiz: quizReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
