import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Region } from 'components/elements/region/Region';
import { DatePicker } from 'components/forms/DatePicker/DatePicker';
import { batch } from 'react-redux';
import {
  closeQuizDate,
  openQuizDate,
  selectQuiz,
  setQuizDate,
  setQuizRegion,
  closeQuizRegion,
  openQuizRegion,
} from '../quiz.slice';
import styles from './quizOne.module.scss';
import { QuizOneProps } from './quizOne.props';

export const QuizOne = ({ regionOptions }: QuizOneProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const quiz = useAppSelector(selectQuiz);

  return (
    <div className={styles.module}>
      {/* <Region
        className={styles.region}
        region={quiz.city}
        isOpen={quiz.ui.regionIsOpen}
        onClick={() =>
          dispatch(quiz.ui.regionIsOpen ? closeQuizRegion() : openQuizRegion())
        }
        onOutsideClick={() => dispatch(closeQuizRegion())}
        onSetRegion={(region) =>
          batch(() => {
            dispatch(setQuizCity(region));
            dispatch(closeQuizRegion());
          })
        }
        options={regionOptions}
      /> */}
      <Region
        className={styles.region}
        region={quiz.region}
        isOpen={quiz.ui.regionIsOpen}
        onClick={() =>
          dispatch(quiz.ui.regionIsOpen ? closeQuizRegion() : openQuizRegion())
        }
        onOutsideClick={() => dispatch(closeQuizRegion())}
        onSetRegion={(region) =>
          batch(() => {
            dispatch(setQuizRegion(region));
            dispatch(closeQuizRegion());
          })
        }
        options={regionOptions}
      />

      <DatePicker
        className={styles.date}
        name="Дата вылета"
        isOpen={quiz.ui.dateIsOpen}
        onClick={() =>
          dispatch(quiz.ui.dateIsOpen ? closeQuizDate() : openQuizDate())
        }
        onOutsideClick={() => dispatch(closeQuizDate())}
        onDayClick={(range) => {
          const value = [];

          if (range?.from) {
            value[0] = range.from.valueOf();
          }
          if (range?.to) {
            value[1] = range.to.valueOf();
          }

          dispatch(setQuizDate(value));

          if (value[1]) {
            dispatch(closeQuizDate());
          }
        }}
        value={quiz.date}
      />
    </div>
  );
};
