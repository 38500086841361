import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import header from './scripts/header';
import gallery from './scripts/gallery';
import directions from './scripts/directions';
import hot from './scripts/hot';
import team from './scripts/team';
import awards from './scripts/awards';
import reviews from './scripts/reviews';
// import callback from './scripts/callback';

import './styles/index.scss';
import { Form } from 'features/form/Form';
import { Quiz } from 'features/quiz/Quiz';

header.init();
gallery.init();
directions.init();
hot.init();
team.init();
awards.init();
reviews.init();
// callback.init();

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

const form = document.getElementById('form');
ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Form
        cityOptions={JSON.parse(form?.dataset.cities ?? '[]')}
        countryOptions={JSON.parse(form?.dataset.countries ?? '[]')}
        nightsOptions={JSON.parse(form?.dataset.nights ?? '[]')}
      />
    </Provider>
  </StrictMode>,
  form
);

const quiz = document.getElementById('quiz');
ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Quiz
        regionOptions={JSON.parse(quiz?.dataset.regions ?? '[]')}
        nightsOptions={JSON.parse(form?.dataset.nights ?? '[]')}
      />
    </Provider>
  </StrictMode>,
  document.getElementById('quiz')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
