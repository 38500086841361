import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  openQuizRegion,
  openQuizDate,
  openQuizNights,
  selectQuiz,
  setQuizStep,
} from '../quiz.slice';
import styles from './quizControl.module.scss';

export const QuizControl = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const quiz = useAppSelector(selectQuiz);

  const { step } = quiz;

  const handleNext = () => {
    if (step === 1 && !quiz.region) {
      dispatch(openQuizRegion());
      return;
    }

    if (step === 1 && quiz.date.length === 0) {
      dispatch(openQuizDate());
      return;
    }

    if (step === 2 && !quiz.nights) {
      dispatch(openQuizNights());
      return;
    }

    dispatch(setQuizStep(step + 1));
  };

  return (
    <div className={styles.module}>
      {step > 1 && step < 3 && (
        <button
          className={styles.prev}
          type="button"
          onClick={() => dispatch(setQuizStep(step - 1))}
        />
      )}

      {step < 3 && (
        <button className={styles.next} type="button" onClick={handleNext}>
          Далее
        </button>
      )}

      {step === 3 && <button className={styles.confirm}>Отправить</button>}
    </div>
  );
};
