import { useAppDispatch } from 'app/hooks';
import { Modal } from 'components/layout/modal/Modal';
import { Portal } from 'components/other/portal/Portal';
import { Transition } from 'components/other/transition/Transition';
import { useSelector } from 'react-redux';
import { closeFormModal, selectForm } from '../form.slice';
import { FormModalBody } from './formModalBody/FormModalBody';

export const FormModal = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const form = useSelector(selectForm);

  return (
    <Transition active={form.ui.modalIsOpen} timeout={300}>
      {(transitionStyles) => (
        <Portal blocked>
          <Modal
            onOverlayClick={() => dispatch(closeFormModal())}
            className={transitionStyles}
          >
            <FormModalBody />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
