import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { FormState } from './form.types';

const initialState: FormState = {
  city: '',
  country: null,
  date: [],
  nights: '',
  adults: 2,
  babies: [],
  name: '',
  phone: '',
  policyIsChecked: false,
  ui: {
    cityIsOpen: false,
    countryIsOpen: false,
    dateIsOpen: false,
    nightsIsOpen: false,
    guestsIsOpen: false,
    modalIsOpen: false,
  },
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormCity: (state, action: PayloadAction<string>) => {
      state.city = action.payload;
    },
    setFormCountry: (
      state,
      action: PayloadAction<{ value: string; name: string }>
    ) => {
      state.country = action.payload;
    },
    setFormDate: (state, { payload }: PayloadAction<number[]>) => {
      state.date = payload;
    },
    setFormNights: (state, action: PayloadAction<string>) => {
      state.nights = action.payload;
    },
    changeFormAdults: (state, { payload }: PayloadAction<number>) => {
      state.adults = payload;
    },
    changeFormBabies: (state, { payload }: PayloadAction<number>) => {
      if (payload > state.babies.length) {
        state.babies = [
          ...state.babies,
          ...Array(payload - state.babies.length).fill(0),
        ];
      } else if (payload < state.babies.length) {
        state.babies = state.babies.filter((_, idx) => idx < payload);
      }
    },
    setFormBaby: (
      state,
      { payload }: PayloadAction<{ idx: number; age: number }>
    ) => {
      state.babies = [
        ...state.babies.slice(0, payload.idx),
        payload.age,
        ...state.babies.slice(payload.idx + 1),
      ];
    },
    setFormName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setFormPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    toggleFormPolicy: (state) => {
      state.policyIsChecked = !state.policyIsChecked;
    },

    clearForm: (state) => {
      state.city = '';
      state.country = null;
      state.date = [];
      state.nights = '';
      state.adults = 2;
      state.babies = [];
      state.name = '';
      state.phone = '';
      state.policyIsChecked = false;
    },

    openFormCity: (state) => {
      state.ui.cityIsOpen = true;
    },
    closeFormCity: (state) => {
      state.ui.cityIsOpen = false;
    },
    openFormCountry: (state) => {
      state.ui.countryIsOpen = true;
    },
    closeFormCountry: (state) => {
      state.ui.countryIsOpen = false;
    },
    openFormDate: (state) => {
      state.ui.dateIsOpen = true;
    },
    closeFormDate: (state) => {
      state.ui.dateIsOpen = false;
    },
    openFormNights: (state) => {
      state.ui.nightsIsOpen = true;
    },
    closeFormNights: (state) => {
      state.ui.nightsIsOpen = false;
    },
    openFormGuests: (state) => {
      state.ui.guestsIsOpen = true;
    },
    closeFormGuests: (state) => {
      state.ui.guestsIsOpen = false;
    },
    openFormModal: (state) => {
      state.ui.modalIsOpen = true;
    },
    closeFormModal: (state) => {
      state.ui.modalIsOpen = false;
    },
  },
});

export const {
  setFormCity,
  setFormCountry,
  setFormDate,
  setFormNights,
  changeFormAdults,
  changeFormBabies,
  setFormBaby,
  setFormName,
  setFormPhone,
  toggleFormPolicy,

  clearForm,

  openFormCity,
  closeFormCity,
  openFormCountry,
  closeFormCountry,
  openFormDate,
  closeFormDate,
  openFormNights,
  closeFormNights,
  openFormGuests,
  closeFormGuests,
  openFormModal,
  closeFormModal,
} = formSlice.actions;

export const selectForm = (state: RootState) => state.form;

export const formReducer = formSlice.reducer;
