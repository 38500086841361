const initMore = () => {
  const directions = document.querySelector('.directions');
  const button = document.querySelector('.directions__more');
  button?.addEventListener('click', () =>
    directions?.classList.add('directions_opened')
  );
};

const directions = {
  init: () => {
    initMore();
  },
};

export default directions;
