import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { QuizState } from './quiz.types';

const initialState: QuizState = {
  step: 1,
  region: '',
  date: [],
  nights: '',
  adults: 2,
  babies: [],
  name: '',
  phone: '',
  policyIsChecked: false,
  ui: {
    regionIsOpen: false,
    dateIsOpen: false,
    nightsIsOpen: false,
    guestsIsOpen: false,
  },
};

export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setQuizStep: (state, { payload }: PayloadAction<number>) => {
      state.step = payload;
    },

    setQuizRegion: (state, action: PayloadAction<string>) => {
      state.region = action.payload;
    },

    setQuizDate: (state, { payload }: PayloadAction<number[]>) => {
      state.date = payload;
    },
    setQuizNights: (state, action: PayloadAction<string>) => {
      state.nights = action.payload;
    },
    changeQuizAdults: (state, { payload }: PayloadAction<number>) => {
      state.adults = payload;
    },
    changeQuizBabies: (state, { payload }: PayloadAction<number>) => {
      if (payload > state.babies.length) {
        state.babies = [
          ...state.babies,
          ...Array(payload - state.babies.length).fill(0),
        ];
      } else if (payload < state.babies.length) {
        state.babies = state.babies.filter((_, idx) => idx < payload);
      }
    },
    setQuizBaby: (
      state,
      { payload }: PayloadAction<{ idx: number; age: number }>
    ) => {
      state.babies = [
        ...state.babies.slice(0, payload.idx),
        payload.age,
        ...state.babies.slice(payload.idx + 1),
      ];
    },
    setQuizName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setQuizPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    toggleQuizPolicy: (state) => {
      state.policyIsChecked = !state.policyIsChecked;
    },

    clearQuiz: (state) => {
      state.step = 1;
      state.region = '';
      state.date = [];
      state.nights = '';
      state.adults = 2;
      state.babies = [];
      state.name = '';
      state.phone = '';
      state.policyIsChecked = false;
    },

    openQuizRegion: (state) => {
      state.ui.regionIsOpen = true;
    },
    closeQuizRegion: (state) => {
      state.ui.regionIsOpen = false;
    },
    openQuizDate: (state) => {
      state.ui.dateIsOpen = true;
    },
    closeQuizDate: (state) => {
      state.ui.dateIsOpen = false;
    },
    openQuizNights: (state) => {
      state.ui.nightsIsOpen = true;
    },
    closeQuizNights: (state) => {
      state.ui.nightsIsOpen = false;
    },
    openQuizGuests: (state) => {
      state.ui.guestsIsOpen = true;
    },
    closeQuizGuests: (state) => {
      state.ui.guestsIsOpen = false;
    },
  },
});

export const {
  setQuizStep,

  setQuizRegion,
  setQuizDate,
  setQuizNights,
  changeQuizAdults,
  changeQuizBabies,
  setQuizBaby,
  setQuizName,
  setQuizPhone,
  toggleQuizPolicy,

  clearQuiz,

  openQuizRegion,
  closeQuizRegion,
  openQuizDate,
  closeQuizDate,
  openQuizNights,
  closeQuizNights,
  openQuizGuests,
  closeQuizGuests,
} = quizSlice.actions;

export const selectQuiz = (state: RootState) => state.quiz;

export const quizReducer = quizSlice.reducer;
