import { useRef } from 'react';
import cn from 'classnames';
import styles from './region.module.scss';
import { RegionProps } from './region.props';

import { Portal } from 'components/other/portal/Portal';
import { Dropdown } from 'components/layout/dropdown/Dropdown';

export const Region = ({
  region,
  options,
  isOpen,
  onClick,
  onOutsideClick,
  onSetRegion,
  className,
}: RegionProps): JSX.Element => {
  const faceRef = useRef<HTMLDivElement>(null);

  const moduleStyles = cn(styles.module, className, {
    [styles.filled]: region,
  });

  return (
    <>
      <div className={moduleStyles} ref={faceRef} onClick={onClick}>
        <span>Регион</span>
        {region && <strong>{region}</strong>}
      </div>
      {isOpen && (
        <Portal>
          <Dropdown
            faceRef={faceRef}
            onOutsideClick={onOutsideClick}
            position="bottomLeft"
          >
            <div className={styles.list}>
              {options.map((item, idx) => (
                <div
                  className={styles.item}
                  key={idx}
                  onClick={() => onSetRegion(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </Dropdown>
        </Portal>
      )}
    </>
  );
};
