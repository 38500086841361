import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Babies } from 'components/elements/babies/Babies';
import { City } from 'components/elements/city/City';
import { CloseButton } from 'components/elements/closeButton/CloseButton';
import { Country } from 'components/elements/country/Country';
import { Guests } from 'components/elements/guests/Guests';
import { Nights } from 'components/elements/nights/Nights';
import { Policy } from 'components/elements/policy/Policy';
import { DatePicker } from 'components/forms/DatePicker/DatePicker';
import { Input } from 'components/forms/input/Input';
import { openTextModal, openThanks } from 'features/common/common.slice';
import { FormEventHandler, MouseEventHandler } from 'react';
import { batch } from 'react-redux';
import {
  changeCalculationAdults,
  changeCalculationBabies,
  clearCalculation,
  closeCalculation,
  closeCalculationCity,
  closeCalculationCountry,
  closeCalculationDate,
  closeCalculationGuests,
  closeCalculationNights,
  openCalculationCity,
  openCalculationCountry,
  openCalculationDate,
  openCalculationGuests,
  openCalculationNights,
  selectCalculation,
  setCalculationBaby,
  setCalculationCity,
  setCalculationCountry,
  setCalculationDate,
  setCalculationName,
  setCalculationNights,
  setCalculationPhone,
  toggleCalculationPolicy,
} from '../calculation.slice';
import styles from './calculationBody.module.scss';
import { CalculationBodyProps } from './calculationBody.props';

declare const ym: any;
// declare const gtag: any;

export const CalculationBody = ({
  cityOptions,
  countryOptions,
  nightsOptions,
}: CalculationBodyProps) => {
  const dispatch = useAppDispatch();
  const calculation = useAppSelector(selectCalculation);

  const titles = {
    calculation: 'Расчет стоимости',
    direction: 'Получить подборку',
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    ym(88411607, 'reachGoal', 'kick');
    // gtag('event', 'submit', { event_category: 'form' });

    dispatch(closeCalculation());
    setTimeout(() => dispatch(openThanks('request')), 300);

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: titles[calculation.type],
        hotel: calculation.hotel,
        city: calculation.city,
        country: calculation.countryString
          ? calculation.countryString
          : calculation.country?.name,
        date:
          calculation.type === 'calculation'
            ? calculation.date
            : calculation.date
                .map((item) => new Date(item).toLocaleDateString().slice(0, -5))
                .join(' - '),
        nights: calculation.nights,
        adults: calculation.adults,
        babies: calculation.babies,
        name: calculation.name,
        phone: calculation.phone,
      }),
    });

    dispatch(clearCalculation());
  };

  const handleConfirm: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (calculation.type === 'direction' && !calculation.city) {
      dispatch(openCalculationCity());
      event.preventDefault();
      return;
    }
    // if (calculation.type === 'direction' && !calculation.country) {
    //   dispatch(openCalculationCountry());
    //   event.preventDefault();
    //   return;
    // }
    if (calculation.type === 'direction' && calculation.date.length === 0) {
      dispatch(openCalculationDate());
      event.preventDefault();
      return;
    }
    if (calculation.type === 'direction' && !calculation.nights) {
      dispatch(openCalculationNights());
      event.preventDefault();
      return;
    }
  };

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeCalculation())}
      />

      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>{titles[calculation.type]}</h2>

        {calculation.type === 'direction' && (
          <City
            className={styles.city}
            city={calculation.city}
            isOpen={calculation.ui.cityIsOpen}
            onClick={() =>
              dispatch(
                calculation.ui.cityIsOpen
                  ? closeCalculationCity()
                  : openCalculationCity()
              )
            }
            onOutsideClick={() => dispatch(closeCalculationCity())}
            onSetCity={(city) =>
              batch(() => {
                dispatch(setCalculationCity(city));
                dispatch(closeCalculationCity());
              })
            }
            options={cityOptions}
          />
        )}

        {calculation.type === 'calculation' ? (
          // <Country
          //   className={styles.country}
          //   country={calculation.country}
          //   isOpen={calculation.ui.countryIsOpen}
          //   onClick={() =>
          //     dispatch(
          //       calculation.ui.countryIsOpen
          //         ? closeCalculationCountry()
          //         : openCalculationCountry()
          //     )
          //   }
          //   onOutsideClick={() => dispatch(closeCalculationCountry())}
          //   onSetCountry={(country) =>
          //     batch(() => {
          //       dispatch(setCalculationCountry(country));
          //       dispatch(closeCalculationCountry());
          //     })
          //   }
          //   options={countryOptions}
          // />
          <></>
        ) : (
          <div className={styles.countryString}>
            <span>Страна</span>
            <strong>{calculation.countryString}</strong>
          </div>
        )}

        {calculation.type === 'direction' && (
          <DatePicker
            className={styles.date}
            name="Дата вылета"
            isOpen={calculation.ui.dateIsOpen}
            onClick={() =>
              dispatch(
                calculation.ui.dateIsOpen
                  ? closeCalculationDate()
                  : openCalculationDate()
              )
            }
            onOutsideClick={() => dispatch(closeCalculationDate())}
            onDayClick={(range) => {
              const value = [];

              if (range?.from) {
                value[0] = range.from.valueOf();
              }
              if (range?.to) {
                value[1] = range.to.valueOf();
              }

              dispatch(setCalculationDate(value));

              if (value[1]) {
                dispatch(closeCalculationDate());
              }
            }}
            value={calculation.date}
          />
        )}

        {calculation.type === 'direction' && (
          <Nights
            className={styles.nights}
            nights={calculation.nights}
            isOpen={calculation.ui.nightsIsOpen}
            onClick={() =>
              dispatch(
                calculation.ui.nightsIsOpen
                  ? closeCalculationNights()
                  : openCalculationNights()
              )
            }
            onOutsideClick={() => dispatch(closeCalculationNights())}
            onSetNights={(nights) =>
              batch(() => {
                dispatch(setCalculationNights(nights));
                dispatch(closeCalculationNights());
              })
            }
            options={nightsOptions}
          />
        )}

        <Guests
          className={styles.guests}
          isOpen={calculation.ui.guestsIsOpen}
          onClick={() =>
            dispatch(
              calculation.ui.guestsIsOpen
                ? closeCalculationGuests()
                : openCalculationGuests()
            )
          }
          onOutsideClick={() => dispatch(closeCalculationGuests())}
          onChangeAdults={(amount) => dispatch(changeCalculationAdults(amount))}
          onChangeBabies={(amount) => dispatch(changeCalculationBabies(amount))}
          onSetBaby={(idx, age) => dispatch(setCalculationBaby({ idx, age }))}
          adults={calculation.adults}
          babies={calculation.babies}
        />

        <Input
          className={styles.name}
          placeholder="Имя"
          value={calculation.name}
          onChange={(event) => dispatch(setCalculationName(event.target.value))}
        />

        <Input
          className={styles.phone}
          placeholder="Телефон"
          type="tel"
          pattern="^\+?[0-9() ]+"
          required
          value={calculation.phone}
          onChange={(event) =>
            dispatch(setCalculationPhone(event.target.value))
          }
        />

        <Policy
          className={styles.policy}
          required
          checked={calculation.policyIsChecked}
          onChange={() => dispatch(toggleCalculationPolicy())}
          onClick={() => dispatch(openTextModal('policy'))}
        />

        <button className={styles.button} onClick={handleConfirm}>
          Отправить
        </button>
      </form>
    </div>
  );
};
