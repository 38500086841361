import { useRef } from 'react';
import cn from 'classnames';
import styles from './nights.module.scss';
import { NightsProps } from './nights.props';

import { Portal } from 'components/other/portal/Portal';
import { Dropdown } from 'components/layout/dropdown/Dropdown';

export const Nights = ({
  nights,
  options,
  isOpen,
  onClick,
  onOutsideClick,
  onSetNights,
  className,
}: NightsProps): JSX.Element => {
  const faceRef = useRef<HTMLDivElement>(null);

  const moduleStyles = cn(styles.module, className, {
    [styles.filled]: nights,
  });

  return (
    <>
      <div className={moduleStyles} ref={faceRef} onClick={onClick}>
        <span>Кол-во ночей</span>
        {nights && <strong>{nights}</strong>}
      </div>
      {isOpen && (
        <Portal>
          <Dropdown
            faceRef={faceRef}
            onOutsideClick={onOutsideClick}
            position="bottomLeft"
          >
            <div className={styles.list}>
              {options.map((item, idx) => (
                <div
                  className={styles.item}
                  key={idx}
                  onClick={() => onSetNights(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </Dropdown>
        </Portal>
      )}
    </>
  );
};
