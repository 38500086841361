import { DayPicker } from 'react-day-picker';
import cn from 'classnames';
import ru from 'date-fns/locale/ru';
import { CalendarProps } from './calendar.props';
import styles from './calendar.module.scss';
import { useState } from 'react';
// import { localization } from './localization';

export const Calendar = ({
  value,
  onDayClick,
  className,
}: CalendarProps): JSX.Element => {
  // const defaultSelected: DateRange = {
  //   from: undefined,
  //   to: undefined,
  // };
  // const [range, setRange] = useState<DateRange | undefined>();

  const range = {
    from: value[0] ? new Date(value[0]) : undefined,
    to: value[1] ? new Date(value[1]) : undefined,
  };

  const moduleStyles = cn(styles.module, className);

  return (
    <div className={moduleStyles}>
      <DayPicker
        locale={ru}
        mode="range"
        selected={range}
        disabled={{
          before: new Date(),
        }}
        onSelect={onDayClick}
      />
    </div>
  );
};
